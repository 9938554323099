import { Link } from "gatsby"
import React from "react"
import bars from "../images/bars.svg"
import times from "../images/times.svg"
import logo from "../images/creative-thinking-institute-logo.svg"
import { useLocation } from "@reach/router"
import styled from "styled-components"

const Special = styled.span`
  padding: 0 4px;
  margin-left: 5px;
  background-color: #7b69ac;
  color: white;
  font-size: 11px;
  border-radius: 4px;
  position: absolute;
  top: -4px;
`

const Header = () => {
  const { pathname } = useLocation()

  return (
    <header>
      <div className="row container">
        <div className="flex flex-ac flex-sb">
          <Link to="/" className="flex flex-ac">
            <img
              src={logo}
              className="logo"
              alt="Creative Thinking Institute (CTI) logo"
            />
          </Link>
          <a
            href="#main-menu"
            role="button"
            id="main-menu-toggle"
            className="menu-toggle"
            aria-expanded="false"
            aria-controls="main-menu"
            aria-label="Open main menu"
          >
            <span className="sr-only">Open main menu</span>
            <img src={bars} aria-hidden="true" alt="menu" />
          </a>

          <nav
            id="main-menu"
            role="navigation"
            className="main-menu"
            aria-expanded="false"
            aria-label="Main menu"
          >
            <a
              href="#main-menu-toggle"
              role="button"
              id="main-menu-close"
              className="menu-close"
              aria-expanded="false"
              aria-controls="main-menu"
              aria-label="Close main menu"
            >
              <span className="sr-only">Close main menu</span>
              <img src={times} aria-hidden="true" alt="click to close" />
            </a>
            <ul>
              <li>
                <Link
                  className={pathname === "/" ? "active" : undefined}
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  className={
                    pathname.indexOf("/about/") === 0 ? "active" : undefined
                  }
                  to="/about/"
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  className={
                    pathname.indexOf("/careers/") === 0 ? "active" : undefined
                  }
                  to="/careers/"
                >
                  Careers
                </Link>
              </li>
              <li style={{ paddingRight: "2em", position: "relative" }}>
                <Link
                  className={
                    pathname.indexOf("/regenemm/") === 0 ? "active" : undefined
                  }
                  to="/regenemm/"
                >
                  Regenemm®<Special>Pilot</Special>
                </Link>
              </li>
              <li>
                <Link
                  className={
                    pathname.indexOf("/blog/") === 0 ? "active" : undefined
                  }
                  to="/blog/"
                >
                  Blog
                </Link>
              </li>
              <li className="nav-btn">
                <Link className="btn btn-navy" to="/contact/">
                  Contact
                </Link>
              </li>
            </ul>
          </nav>

          <a
            href="#main-menu-toggle"
            className="backdrop"
            tabIndex="-1"
            aria-hidden="true"
            hidden
          ></a>
        </div>
      </div>
    </header>
  )
}

export default Header
