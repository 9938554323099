import { Link } from "gatsby"
import React from "react"
import Twitter from "../images/twitter-white-brands.svg"
import Fb from "../images/facebook-f-white-brands.svg"
import Insta from "../images/instagram-white-brands.svg"
import Li from "../images/linkedin-in-white-brands.svg"
import LogoVert from "../images/CTI-stacked.svg"
import styled from "styled-components"
import Newsletter from "./newsletter"
import CookieConsent from "./cookieConsent"

const Footer = styled.footer`
  background-color: #1e1a49;
`

const FooterLink = styled(Link)`
  color: white;
`

const Copyright = styled.div`
  color: white;
  a {
    color: white;
  }
`

const Title = styled.h6`
  color: white;
`

export default () => (
  <>
    <CookieConsent />
    <div style={{ marginTop: "5rem" }}></div>
    <Newsletter />
    <Footer>
      <div className="container">
        <div className="row">
          <div className="grid-4-col-footer">
            <div className="footer-logo">
              <img src={LogoVert} alt="Creative Thinking Institute logo" />
            </div>
            <div className="footer-menu">
              <Title>Company</Title>
              <ul className="list-unstyled mt-0">
                <li>
                  <FooterLink to="/">Home</FooterLink>
                </li>
                <li>
                  <FooterLink to="/about/">About</FooterLink>
                </li>
                <li>
                  <FooterLink to="/careers/">Careers</FooterLink>
                </li>
                <li>
                  <FooterLink to="/regenemm/">Regenemm®</FooterLink>
                </li>
                <li>
                  <FooterLink to="/blog/">Blog</FooterLink>
                </li>
                <li>
                  <FooterLink to="/contact/">Contact</FooterLink>
                </li>
              </ul>
            </div>

            <div className="social-icons">
              <Title>Social</Title>
              <a href="https://twitter.com/CTI_Pty_Ltd">
                <img src={Twitter} alt="Twitter icon" />
              </a>
              <a href="https://www.facebook.com/Creative-Thinking-Institute-100202631717583/">
                <img src={Fb} alt="Facebook icon" />
              </a>
              <a href="https://www.instagram.com/creativethinkinginstitute/">
                <img src={Insta} alt="Instagram icon" />
              </a>
              <a href="http://linkedin.com/company/creative-thinking-institute">
                <img src={Li} alt="Linkedin icon" />
              </a>
            </div>
          </div>
        </div>

        <Copyright
          className="row copyright-row flex flex-wrap"
          itemScope=""
          itemType="http://schema.org/LocalBusiness"
        >
          <div className="">
            <span>© Copyright</span>
            <a
              className="co-name"
              itemProp="url"
              href="https://creativethinkinginstitute.com"
            >
              <span itemProp="name">Creative Thinking Institute Pty Ltd.</span>
            </a>
            <span>2020</span>
          </div>
          <Link to="/terms/">Terms &amp; Conditions</Link>
          <Link to="/privacy/">Privacy</Link>
        </Copyright>
      </div>
    </Footer>
  </>
)
