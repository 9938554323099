import React, { useRef, useState } from "react"
import styled from "styled-components"
import iPhoneHome from "../images/iPhone_home.png"

const SignupImage = styled.img`
  max-width: 100%;
  width: 400px;
  display: block;

  @media (max-width: 992px) {
    display: none;
  }
`

const Newsletter = styled.section`
  border-top: 1px solid #e7e7ec;
  background: #f4f4f9;
  padding: 32px 0 0 0;
`

const SubscribeHeading = styled.div`
  text-align: center;
  margin-bottom: 32px;
`

const McFieldGroup = styled.div`
  margin-bottom: 16px;
`

const Input = styled.input`
  padding: 10px;
  &[type="submit"] {
    width: 100px;
    float: right;
  }
`

const WaysToHear = styled.fieldset`
  margin: 0;
  padding: 0;
  border: none;
`

const WaysToHearLabel = styled.label`
  display: flex;
  align-items: center;
  height: 21px;
`

const WaysToHearCheckbox = styled.input`
  display: inline-block;
  width: auto;
  margin-right: 10px;
`

const MCDisclamer = styled.p`
  font-size: 11px;
  line-height: 14px;
`

const Anchor = styled.a`
  color: #37474f;
  font-family: "Poppins", "Helvetica", "Arial", "sans-serif";
  font-weight: 400;
`

const FormStep = styled.div`
  display: ${props => (props.isCurrentStep ? "block" : "none")};
`

export default () => {
  const alreadySubscribed = useRef(
    typeof window !== "undefined"
      ? localStorage.getItem("subscribedToNewsletter")
      : undefined
  )

  const [state, setState] = useState({})
  const [formStep, setFormStep] = useState(0)

  const handleSubmit = e => {
    if (formStep === 0) e.preventDefault()
    else localStorage.setItem("subscribedToNewsletter", true)

    setFormStep(previousState => previousState + 1)
  }

  const handleChange = key => value =>
    setState(previousState => ({ ...previousState, [key]: value }))

  return !alreadySubscribed.current ? (
    <Newsletter id="footer-newsletter">
      <div className="container">
        <div className="row" style={{ maxWidth: 1000, margin: "auto" }}>
          <div id="mc_embed_signup">
            <form
              action="https://creativethinkinginstitute.us10.list-manage.com/subscribe/post?u=2bce18af8b4b1b112c094742a&amp;id=cfe55f65d7"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              className="validate"
              target="_blank"
              onSubmit={handleSubmit}
            >
              <div id="mc_embed_signup_scroll">
                <SubscribeHeading>
                  <h2 className="text-center">Subscribe</h2>
                  <p>
                    Sign up to the CTI newsletter for the latest insights,
                    research and Regenemm® news.
                  </p>
                </SubscribeHeading>
                <div className="grid-2-col">
                  <div>
                    <SignupImage src={iPhoneHome} />
                  </div>
                  <div>
                    <FormStep isCurrentStep={formStep === 0}>
                      <McFieldGroup>
                        <label htmlFor="mce-FNAME">
                          First Name <span className="asterisk">*</span>
                        </label>
                        <Input
                          type="text"
                          value={state.firstName}
                          onChange={e =>
                            handleChange("firstName")(e.target.value)
                          }
                          name="FNAME"
                          id="mce-FNAME"
                          required
                        />
                      </McFieldGroup>
                      <McFieldGroup>
                        <label htmlFor="mce-LASTNAME">
                          Last Name <span className="asterisk">*</span>
                        </label>
                        <Input
                          type="text"
                          value={state.lastName}
                          onChange={e =>
                            handleChange("lastName")(e.target.value)
                          }
                          name="LASTNAME"
                          id="mce-LASTNAME"
                          required
                        />
                      </McFieldGroup>
                      <McFieldGroup>
                        <label htmlFor="mce-EMAIL">
                          Work Email Address <span className="asterisk">*</span>
                        </label>
                        <Input
                          type="email"
                          value={state.email}
                          onChange={e => handleChange("email")(e.target.value)}
                          name="EMAIL"
                          id="mce-EMAIL"
                          required
                        />
                      </McFieldGroup>
                      <div className="content__gdprLegal">
                        <MCDisclamer>
                          We use Mailchimp as our marketing platform. By
                          clicking below to subscribe, you acknowledge that your
                          information will be transferred to Mailchimp for
                          processing.{" "}
                          <Anchor
                            href="https://mailchimp.com/legal/"
                            target="_blank"
                          >
                            Learn more about Mailchimp's privacy practices here.
                          </Anchor>
                        </MCDisclamer>
                        <McFieldGroup>
                          <Input
                            type="submit"
                            value="Next"
                            name="subscribe"
                            id="mc-embedded-subscribe"
                            className="btn-green-solid"
                            style={{ cursor: "pointer" }}
                          />
                          <br />
                          <br />
                        </McFieldGroup>
                      </div>
                    </FormStep>
                    <FormStep
                      isCurrentStep={formStep === 1}
                      id="mergeRow-gdpr"
                      className="mergeRow gdpr-mergeRow
                            content__gdprBlock mc-field-group"
                    >
                      <div className="content__gdpr">
                        <p>
                          Please select all the ways you would like to hear from
                          us:
                        </p>
                        <WaysToHear
                          className="mc_fieldset gdprRequired mc-field-group"
                          name="interestgroup_field"
                        >
                          <WaysToHearLabel
                            className="checkbox subfield"
                            htmlFor="gdpr_48689"
                          >
                            <WaysToHearCheckbox
                              type="checkbox"
                              id="gdpr_48689"
                              name="gdpr[48689]"
                              value="Y"
                              className="av-checkbox gdpr"
                            />
                            <span>Newsletter</span>{" "}
                          </WaysToHearLabel>
                          <WaysToHearLabel
                            className="checkbox subfield"
                            htmlFor="gdpr_48765"
                          >
                            <WaysToHearCheckbox
                              type="checkbox"
                              id="gdpr_48765"
                              name="gdpr[48765]"
                              value="Y"
                              className="av-checkbox gdpr"
                            />
                            <span>Regenemm® Updates</span>{" "}
                          </WaysToHearLabel>
                        </WaysToHear>
                        <br />
                        <MCDisclamer>
                          You can change your mind at any time by clicking the
                          unsubscribe link in the footer of any email you
                          receive from us, or by contacting us at
                          info@creativethinkinginstitute.com.
                        </MCDisclamer>
                        <McFieldGroup>
                          <Input
                            type="submit"
                            value="Subscribe"
                            name="subscribe"
                            id="mc-embedded-subscribe"
                            className="btn-green-solid"
                            style={{ cursor: "pointer" }}
                          />{" "}
                          <br />
                          <br />
                        </McFieldGroup>
                      </div>
                    </FormStep>
                    <FormStep isCurrentStep={formStep > 1}>
                      <h6>Thanks for subscribing</h6>
                    </FormStep>
                  </div>
                </div>
                <div id="mce-responses" className="clear">
                  <div
                    className="response"
                    id="mce-error-response"
                    style={{ display: "none" }}
                  ></div>
                  <div
                    className="response"
                    id="mce-success-response"
                    style={{ display: "none" }}
                  ></div>
                </div>{" "}
                <div
                  style={{ position: "absolute", left: "-5000px" }}
                  aria-hidden="true"
                >
                  <input
                    type="text"
                    name="b_2bce18af8b4b1b112c094742a_cfe55f65d7"
                    tabIndex="-1"
                    aria-label="b_2bce18af8b4b1b112c094742a_cfe55f65d7"
                    value={state.b_2bce18af8b4b1b112c094742a_cfe55f65d7}
                    onChange={e =>
                      handleChange(
                        "b_2bce18af8b4b1b112c094742a_cfe55f65d7",
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Newsletter>
  ) : null
}
