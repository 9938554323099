/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import "typeface-poppins"
import Header from "./header"
import Footer from "./footer"
import SEO from "./seo"

const Layout = ({ children, meta }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          siteUrl
          title
        }
      }
    }
  `)

  const imageUrl = useMemo(() => {
    if (!meta.image) {
      return undefined
    }
    return data.site.siteMetadata.siteUrl + meta.image.childImageSharp.fluid.src
  }, [data, meta])

  return (
    <div className="site-wrapper">
      <SEO
        title={meta.title}
        description={meta.description}
        imageUrl={imageUrl}
        noIndex={meta.noIndex}
      />
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  meta: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    noIndex: PropTypes.bool,
    image: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({
          src: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }),
  }).isRequired,
  children: PropTypes.node.isRequired,
}

export default Layout

export const query = graphql`
  fragment Layout_metaImage on File {
    childImageSharp {
      fluid(maxWidth: 1200, quality: 95) {
        src
      }
    }
  }
`
