import React from "react"
import { useLocation } from "@reach/router"
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"
import { Link } from "gatsby"
import CookieConsent from "react-cookie-consent"

export default () => {
  const location = useLocation()

  return (
    <CookieConsent
      overlay={true}
      overlayStyle={{
        height: "62px",
        bottom: "0",
        top: "unset",
        backgroundColor: "#1e1e1e",
        display: "flex",
        justifyContent: "center",
      }}
      location="bottom"
      buttonText="Accept"
      declineButtonText="Decline"
      cookieName="gatsby-gdpr-google-analytics"
      contentStyle={{
        margin: "16px 0 0 0",
        fontSize: "16px",
        flex: "auto",
      }}
      containerClasses={["container"]}
      style={{
        justifyContent: "flex-end",
        background: "#1e1e1e",
        padding: "0 26px",
        left: "unset",
        right: "unset",
      }}
      buttonStyle={{
        color: "#1e1e1e",
        fontSize: "16px",
        backgroundColor: "#01bdbe",
        padding: "6px 20px",
        borderRadius: "8px",
        margin: "16px 0 16px 16px",
      }}
      declineButtonStyle={{
        color: "white",
        fontSize: "13px",
        backgroundColor: "transparent",
        textDecoration: "underline",
        margin: "16px 0",
        padding: "0 0 0 16px",
      }}
      expires={150}
      enableDeclineButton
      onAccept={() => initializeAndTrack(location)}
    >
      This website uses cookies to offer you a better experience and for
      analytical purposes. <Link to="/privacy">Learn more</Link>
    </CookieConsent>
  )
}
