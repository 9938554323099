/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const defaultTitle =
  "Optimise adaptability and human potential - Creative Thinking Institute"

function SEO({ description, title, imageUrl, noIndex }) {
  const { site, defaultImage } = useStaticQuery(
    graphql`
      query SEOQuery {
        site {
          siteMetadata {
            siteUrl
            title
            description
            author
          }
        }
        defaultImage: file(relativePath: { eq: "social-sharing.jpg" }) {
          childImageSharp {
            fluid(quality: 95, maxWidth: 1200) {
              src
            }
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const metaTitle = title || defaultTitle
  const metaImageUrl =
    imageUrl ||
    site.siteMetadata.siteUrl + defaultImage.childImageSharp.fluid.src

  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
      title={metaTitle}
      titleTemplate={`%s - ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: "og:image",
          content: metaImageUrl,
        },
        {
          property: "twitter:image",
          content: metaImageUrl,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        ...(noIndex ? [{ name: "robots", content: "noindex" }] : []),
      ]}
    />
  )
}

SEO.defaultProps = {
  description: ``,
  noIndex: false,
}

SEO.propTypes = {
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  title: PropTypes.string,
  noIndex: PropTypes.bool,
}

export default SEO
